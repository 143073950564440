<template>
  <footer class="py-5">

    <div class="container">
      <div class="row justify-content-between">
        <div class="col-auto">
          <p class="mb-0">Pesce Logistica 2021 © Tutti i diritti riservati.</p>
        </div>
        <div class="col-auto">
          <p class="mb-0">Made with <heart-icon size="1.2x" class="text-danger"></heart-icon> by <a href="https://www.beexel.it" class="text-warning" target="_blank">Beexel</a></p>
        </div>
      </div>
    </div>

    <hr>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-auto">
          <button class="btn btn-light">Privacy Policy</button>
          <button class="btn btn-light">Termini e condizioni</button>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { HeartIcon } from "vue-feather-icons"

export default {
  name: "footer",
  components: { HeartIcon }
}
</script>

<style scoped>

</style>