<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="assets/logo.webp" class="logo img-fluid" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item"><a class="nav-link active" aria-current="page" href="#azienda">Azienda</a></li>
          <li class="nav-item"><a class="nav-link active" aria-current="page" href="#contatti">Contatti</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar"
}
</script>

<style scoped>
  nav.navbar {
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    background: rgba(0, 0, 0, 0.3);
  }

  .logo {
    max-height: 50px;
  }
</style>